import { render, staticRenderFns } from "./CreateControlDrugAdmin.vue?vue&type=template&id=432a60f2&scoped=true&"
import script from "./CreateControlDrugAdmin.vue?vue&type=script&lang=js&"
export * from "./CreateControlDrugAdmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432a60f2",
  null
  
)

export default component.exports