    <template>
   <v-row justify="center">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <v-card-title> Create Control Drug </v-card-title>
        <v-card-text>
                  <v-text-field
                    v-model="name"
                    label="Control Drug Name"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="directive"
                    label="Directives"
                    required
                  ></v-text-field>
                    <v-datetime-picker label="Given Date & Time"
                                   v-model="givenDateTime" timePickerFormat="24hr"
                                   dateFormat="dd-MM-yyyy">
                    </v-datetime-picker>
                  <v-select
                    label="Control Drug"
                    v-model="controlDrug"
                    :items="controlDrugList"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                  <v-text-field
                    v-model="balance"
                    label="Balance"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="witness"
                    label="Witness"
                    required
                  ></v-text-field>
                  <v-btn
                    color="warning"
                    @click="register"
                  >
                    Submit
                  </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
   </v-row>
</template>

<script>
import {mapGetters, mapActions } from 'vuex'
import controlDrugApi from '../../services/api/ControlDrugRegisterAPI';
import moment from 'moment';

export default {
    name:'registerControlDrug',
    data () {
      return {
        name: '',
        directive: '',
        givenDateTime:'',
        controlDrug:'',
        resident: '',
        user: '',
        witness:'',
        balance: '',
        controlDrugList:[],
      }
    },
    computed: {
        ...mapGetters([
           'getLoggedUser',
           'allControlDrug',
        ]),
    },
     created() {
       this.getLoggedInUser();
       this.user = this.getLoggedUser.user,
       this.resident = this.$route.params.residentId;
       this.controlDrugForResident();

    },
    methods: {
      ...mapActions([
          'register_control_drug_admin',
          'getLoggedInUser',
      ]),
      register () {
        delete this.$data.controlDrugList;
        this.$data.givenDateTime = this.formatDate(this.$data.givenDateTime);
        this.register_control_drug_admin({
          ...this.$data
        })
        /* eslint-disable */
        .then(response => {
            this.$router.push('/controlDrugAdmin')
        })
        .catch(error => {
            console.log(error)
        })
      },
      controlDrugForResident () {
          return new Promise((resolve, reject) => {
              controlDrugApi.getRegisteredControlDrug({page: '', count:'', id:this.resident, q:'', orderBy: '', order: ''})
                .then(controlDrug => {
                    this.controlDrugList = controlDrug.result
                })
                .catch(error => {
                    return reject(error)
                })
          })
      },
      formatDate(date) {
        date = moment(date).format('DD-MM-YYYY HH:mm');
        return date
      }
    },
}
</script>

<style lang="stylus" scoped>

</style>
